<template>
  <div class="container">
    <div v-if="myProducts.length>0">
      <header class="modal-card-head">
        <p class="modal-card-title">Panier</p>
      </header>
      <form @submit.prevent="buy(myProducts)">
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <br>
              <p class="heading">Prix total</p>
              <p class="title">{{ Number(myTotalPrice).toFixed(2) }} CHF</p>
            </div>
          </div>
        </nav>
        <b-table :data="myProducts">
          <b-table-column label="Produit" v-slot="props">
            <b-field class="has-text-left">
            <span @click="$router.push({ name: 'getProduct', params: {id: props.row.id } })"
                  style="cursor:pointer;">{{ props.row.name }}</span>
            </b-field>
          </b-table-column>
          <b-table-column label="Quantité" centered v-slot="props">
            <b-field>
              <b-input
                v-if="props.row.is_orderable"
                type="number"
                v-model="props.row.cpt"
                min="1"
                @input="updateCart(props.row)"
                size="is-small"
                required
              ></b-input>
              <b-input
                v-if="!props.row.is_orderable"
                type="number"
                v-model="props.row.cpt"
                min="1"
                :max="props.row.stock"
                @input="updateCart(props.row)"
                size="is-small"
                required
              ></b-input>
            </b-field>
          </b-table-column>
          <b-table-column label="Prix unitaire" centered v-slot="props">
            {{ Number(props.row.price).toFixed(2) }} CHF
          </b-table-column>
          <b-table-column label="Prix total" centered v-slot="props">
            {{ Number((props.row.price * props.row.cpt)).toFixed(2) }} CHF
          </b-table-column>
          <b-table-column v-slot="props">
            <b-button type="is-danger" @click="removeProduct(props.row)">Supprimer</b-button>
          </b-table-column>
        </b-table>
        <br>
        <div>
          <b-button
            class="is-pulled-left"
            label="Commander"
            native-type="submit"
            type="is-success submit"/>
        </div>
        <div>
          <b-button class="is-pulled-right" type="is-danger" @click="empty">Vider le panier</b-button>
        </div>
      </form>
    </div>
    <div v-else>
      <section class="hero">
        <div class="hero-body">
          <p class="title">
            Votre panier est vide.
          </p>
          <p class="subtitle">
            <span>Cliquez </span>
            <a @click="$router.push({ name: 'getProducts' })" style="cursor:pointer;">ici</a>
            <span> pour afficher nos produits.</span>
          </p>
        </div>
      </section>
    </div>
    <br><br>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import router from '@/router'
import {imageMixin} from '@/mixins/image'
import notification from "@/mixins/notification";

export default {
  name: 'GetCart',
  data: () => {
    return {
      totalPrice: 0,
      overStock: false
    }
  },
  mixins: [imageMixin, notification],
  computed: {
    ...mapGetters(['myProducts', 'nbMyProducts', 'myTotalPrice'])
  },
  methods: {
    goProduct: function (product) {
      router.push({
        name: 'getProduct',
        params: product.id
      })
    },
    removeProduct: function (product) {
      this.$store.commit('DELETE_PRODUCT', product)
    },
    empty: function () {
      this.$store.commit('EMPTY_CART')
      this.showNotification('Panier vidé.', 'success');
    },
    updateCart(product) {
      if (product.cpt.length === 0) {
        product.cpt = 0
      }
      this.$store.commit('UPDATE_CART', product)
    },
    buy: function (products) {
      const myBuys = []
      let nbOver = 0
      for (let i = 0; i < products.length; i++) {
        if (products[i].cpt > products[i].stock && !products[i].is_orderable) {
          nbOver++
        }
        const myProduct = {}
        myProduct.product_id = products[i].id
        myProduct.quantity = products[i].cpt
        myBuys.push(myProduct)
      }
      this.overStock = nbOver > 0
      if (!this.overStock) {
        this.$store.commit('ORDER_OK', true)
        router.push({name: 'ValidationOrder'})
      } else {
        this.$toast.clear()
        this.$toast.open({
          message: 'Vous ne pouvez pas dépasser le nombre total de produits en stock.',
          type: 'warning',
          position: 'top'
        })
      }
    }
  }
}
</script>

