import http from '@/http';

export const eventModule = {

  state: {
    event: {},
    tmpEvent: false,
    eventDone: false,
    eventStatus: false,
  },

  getters: {
    myEvent: state => state.event,
    myEventDone: state => state.eventDone,
    myTmpEvent: state => state.tmpEvent,
    myEventStatus: state => state.eventStatus,
  },

  mutations: {
    EVENT_OK(state, resp) {
      state.eventStatus = resp;
    },
    selectedEvent(state, _event) {
      state.event = _event;
    },
    changeTmpEvent(state, resp) {
      state.tmpEvent = resp;
    },
    doneEvent(state, resp) {
      state.eventDone = resp;
    },
  },

  actions: {
    payEvent({ commit },obj) {
      return http.post('/participants', obj)
        .catch(err => {
          console.error(err);
          console.log(err.response)
          throw err.response;
        });
    },
    getEvents({ commit }, obj) {
      const id = obj.id;
      return http.get(`/users/${id}/events`)
        .then((response) => {
          return response.data;
        })
        .catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    getEvent(idEvent) {
      return http.get(`/events/${idEvent}`)
        .catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    isParticipant({ commit }, idEvent) {
      return http.get(`/is-participant/${idEvent}`)
        .then((response) => {
          return response.data
        })
        .catch(err => {
          console.error(err);
          throw err.response;
        });
    },
  },
};


