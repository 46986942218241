<template>
  <div>
    <b-carousel
      :pause-info="false"
      :arrow-hover="false"
      icon-size="is-large"
      :indicator="true"
      :indicator-background="true"
      indicator-position="is-bottom"
      indicator-style="is-dots"
    >
      <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
        <section :class="`hero is-medium is-${carousel.color}`">
          <div class="hero-body has-text-centered">
            <h1 class="title">{{ carousel.text }}</h1>
            <b-button @click="$router.push({ name: carousel.path })">{{ carousel.button }}</b-button>
          </div>
        </section>
      </b-carousel-item>
    </b-carousel>
    <div class="section container">
      <div class="columns">
        <div class="column">
          <h3 class="is-size-1">Actualités</h3>
          <div v-if="window.width > 500">
            <iframe class="iframe-fb-desktop"
                    :src='"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLAr%25C3%25A8ne-de-Duel-Gen%25C3%25A8ve-151429444951260&tabs=timeline" +
                 "&width=500&height=1000&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId"'
                    :allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div v-else>
            <iframe class="iframe-fb-mobile"
                    :src='"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLAr%25C3%25A8ne-de-Duel-Gen%25C3%25A8ve-151429444951260&tabs=timeline" +
                 "&width=500&height=1000&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId"'
                    :allowfullscreen="true"
                    :lazy="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
        </div>
        <div class="column">
          <div>
          </div>
          <GetEvents/>
          <div >
            <h3 class="title">Boutique</h3>
            <div v-show="false">
            <div v-if="window.width > 500">
              <iframe :width="500" :height="500" style="border:0" loading="lazy" allowfullscreen
                      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJF7y1fqZkjEcR1qxGZlhmGG8&key=AIzaSyCrMs4m2ORSG7UvfC7Tt307BGi3gHsgunQ"></iframe>
            </div>
            <div v-else>
              <iframe :width="300" :height="400" style="border:0" loading="lazy" allowfullscreen
                      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJF7y1fqZkjEcR1qxGZlhmGG8&key=AIzaSyCrMs4m2ORSG7UvfC7Tt307BGi3gHsgunQ"></iframe>

            </div>
            </div>
            <br>
            <div class="has-text-centered">
              <b-icon pack="fas" icon="store"></b-icon>
              {{ this.info.name }} <br>
              <b-icon pack="fas" icon="map-marker-alt"></b-icon>
              {{ this.info.address }} <br>
              <b-icon pack="fas" icon="envelope"></b-icon>
              <a @click="$router.push({ name: 'Contact' })">Contact</a><br>
              <b-icon pack="fas" icon="phone"></b-icon>
              {{ this.info.phone }}<br>
              {{ this.info.others }}<br>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import GetEvents from '@/components/Events/Events'

export default {
  name2: 'Events',
  components: {GetEvents},
  data: () => {
    return {
      products: [],
      info: {},
      carousels: [
        {text: 'Bienvenue à l\'Arène de Duel!', button: 'Nous contacter', color: 'primary', path: 'Contact'},
        {text: 'Consultez nos produits', button: 'Plus d\'informations', color: 'link', path: 'getProducts'},
        {text: 'et nos événements', button: 'Plus d\'informations', color: 'info', path: 'getEvents'},
      ]
    }
  },
  name: 'Products',
  computed: {
    ...mapGetters(['window'])
  },
  mounted() {
    this.$store.dispatch('info').then(
      (data) => {
        this.info = data.data
      }
    )
  },
}
</script>

<style scoped>
.iframe-fb-desktop {
  width: 500px;
  height: 1000px;
}

.iframe-fb-mobile {
  width: 300px;
  height: 550px;
}

</style>
