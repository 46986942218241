export const responsiveModule = {

  state: {
    mobile: false,
    sizeWindow: {},
  },

  getters: {
    isMobile: state => state.mobile,
    window: state => state.sizeWindow
  },

  mutations: {
    MOBILE_STATUS(state, resp) {
      state.mobile = resp;
    },
    SET_WINDOW_SIZE(state, width, height) {
      state.sizeWindow.width = width;
      state.sizeWindow.height = height;
    },
  },
}
