import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/components/Home/Home';
import store from '@/store';
import GetCart from '@/components/Orders/Cart';

Vue.use(VueRouter);

const routes = [

  { path: '/', redirect: '/home' },
  {
    path: '*',
    redirect: '/home'
  },
  {
    path: '/products',
    name: 'getProducts',
    component: () => import('@/views/Products')
  },
  {
    path: '/events',
    name: 'getEvents',
    component: () => import('@/components/Events/Events')
  },
  {
    path: '/events/:id',
    name: 'getEvent',
    component: () => import('@/components/Events/Event')
  },
  {
    path: '/products/:id',
    name: 'getProduct',
    component: () => import('@/components/Products/Product')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/Auth/LoginForm')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/components/Auth/RegisterForm')
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/mycart',
    name: 'Cart',
    component: GetCart
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/components/Account/MyAccount'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/validationorder',
    name: 'ValidationOrder',
    component: () => import('@/components/Orders/OderValidation'),
    meta: {
      requiresAuth: true,
      requiresOrder: true
    }
  },
  {
    path: '/confirmationorder',
    name: 'ConfirmationOrder',
    component: () => import('@/components/Orders/OrderConfirmation'),
    meta: {
      requiresAuth: true,
      requiresOrderDone: true
    }
  },
  {
    path: '/orders/:id',
    name: 'getOrder',
    component: () => import('@/components/Account/MyOrder'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/validationevent',
    name: 'ValidationEvent',
    component: () => import('@/components/Events/EventValidation'),
    meta: {
      requiresAuth: true,
      requiresEvent: true
    }
  },
  {
    path: '/confirmationEvent',
    name: 'ConfirmationEvent',
    component: () => import('@/components/Events/EventConfirmation'),
    meta: {
      requiresAuth: true,
      requiresEventDone: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/components/Contact/Contact')
  },
  {
    path: '/recover',
    name: 'ResetPassword',
    component: () => import('@/components/Auth/Recover'),
    meta: {
      recover: true,
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.matched.some(record => record.meta.requiresOrder)) {
      if (!store.getters.orderStatus) {
        next('/');
      }
    }
    if (to.matched.some(record => record.meta.requiresOrderDone)) {
      if (store.getters.myOrder === 0) {
        next('/');
      }
    }
    if (to.matched.some(record => record.meta.requiresEvent)) {
      if (!store.getters.myEventStatus) {
        next('/events');
      }
    }
    if (to.matched.some(record => record.meta.requiresEventDone)) {
      if (!store.getters.myEventDone) {
        next('/events');
      }
    }
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    if (store.getters.isLoggedIn && to.path === '/register') {
      next('/account');
      return;
    }
    if (store.getters.isLoggedIn && to.path === '/login') {
      next('/account');
      return;
    }
    if (to.matched.some(record => record.meta.recover)) {
      if (store.getters.isLoggedIn) {
        next('/account');
        return;
      }
    }
    next();
  }
});

export default router;
