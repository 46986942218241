import http from '@/http';

export const orderModule = {

  state: {
    nbProducts: 0,
    products_cart: [],
    myTotalPrice: 0,
    orderStatus: false,
    order: 0,
    overStock: false,
  },

  getters: {
    orderStatus: state => state.orderStatus,
    myProducts: state => state.products_cart,
    nbMyProducts: state => state.nbProducts,
    myTotalPrice: state => state.myTotalPrice,
    myOverStock: state => state.overStock,
    myOrder: state => state.order,
  },

  mutations: {
    RESET_CART(state) {
      state.nbProducts = 0
      state.products_cart = []
      state.myTotalPrice = 0.0
      state.orderStatus = false
      state.order = 0
      state.overStock = false
    },
    ORDER_OK(state, resp) {
      state.orderStatus = resp
    },
    UPDATE_CART(state) {
      state.nbProducts = 0
      state.myTotalPrice = 0
      for (let i = 0; i < state.products_cart.length; i++) {
        state.nbProducts = state.nbProducts + parseInt(state.products_cart[i].cpt)
        state.myTotalPrice = state.myTotalPrice + parseFloat(state.products_cart[i].price) * parseFloat(state.products_cart[i].cpt)
      }
    },
    ADD_PRODUCT(state, product) {
      let myIndex = -1
      for (let i = 0; i < state.products_cart.length; i++) {
        if (state.products_cart[i].id === product.id) {
          myIndex = i
        }
      }
      if (myIndex === -1) {
        state.overStock = false
        product.cpt = 1
        state.nbProducts++
        state.products_cart.push(product)
        state.myTotalPrice = state.myTotalPrice + product.price
      } else {
        const myProduct = state.products_cart[myIndex]
        if (myProduct.is_orderable) {
          state.overStock = false
          myProduct.cpt++
          state.nbProducts++
        } else if (myProduct.cpt + 1 > myProduct.stock) {
          state.overStock = true
        } else {
          state.overStock = false
          myProduct.cpt++
          state.nbProducts++
        }
        state.myTotalPrice = 0
        for (let i = 0; i < state.products_cart.length; i++) {
          state.myTotalPrice = state.myTotalPrice + parseFloat(state.products_cart[i].price) * parseFloat(state.products_cart[i].cpt)
        }
      }
    },
    DELETE_PRODUCT(state, product) {
      const index = state.products_cart.indexOf(product)
      if (index > -1) {
        state.products_cart.splice(index, 1)
        state.nbProducts = state.nbProducts - product.cpt
        state.myTotalPrice = state.myTotalPrice - parseFloat(product.price) * parseInt(product.cpt)
      }
      if (state.nbProducts <= 0) {
        state.orderStatus = false
      }
    },
    EMPTY_CART(state) {
      state.products_cart = []
      state.nbProducts = 0
      state.myTotalPrice = 0
      state.orderStatus = false
    },
    addOrder(state, _order) {
      state.order = _order
    },
    removeOrder(state) {
      state.order = 0
    },
  },

  actions: {
    order({commit}, products) {
      return http.post('/buy', products)
        .then((response) => {
          commit('addOrder', response.data.id)
        }).catch((error) => {
          console.log(error)
          console.log(error.response)
        })
    },
    getOrders({commit}, obj) {
      const id = obj.id;
      return http.get(`users/${id}/orders`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getOrder({commit}, idOrder) {
      return http.get(`orders/${idOrder}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    payOrder({commit}, idOrder) {
      return http.post(`pay/${idOrder}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error)
      })
    },
  },
};


