import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import VueToast from 'vue-toast-notification'
import VCalendar from 'v-calendar'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)
Vue.use(VCalendar)

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
