import axios from 'axios';
import store from '@/store';

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API}/api`,
  headers: {
    'Accept': 'application/json',
    'Content': 'application/json'
  },
});

http.interceptors.request.use(config => {
  const token = store.state.auth.token;
  if (token)
    config.headers.Authorization = `Bearer ${token}`;
  return config;
}, err => Promise.reject(err));

export default http;
