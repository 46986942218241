import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import {orderModule} from '@/store/order-module';
import {authModule} from "@/store/auth-module";
import {eventModule} from "@/store/event-module";
import {responsiveModule} from "@/store/responsive-module";
import {filterModule} from "@/store/filter-module";
import {infoModule} from "@/store/info-module";

Vue.use(Vuex);

const persistAuth = new VuexPersistence({
  modules: ['auth', 'order']
});

export default new Vuex.Store({
  modules: {
    order: orderModule,
    auth: authModule,
    event: eventModule,
    responsive: responsiveModule,
    filter: filterModule,
    info: infoModule
  },
  plugins: [persistAuth.plugin],
});
