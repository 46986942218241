import http from '@/http';

export const infoModule = {

  actions: {
    sendMail({ commit },mail) {
      return http.post('/contact', mail)
        .catch(err => {
          console.log(err.response)
          console.error(err);
          throw err.response;
        });
    },
    info({ commit }) {
      return http.get('/info')
        .catch(err => {
          console.log(err.response)
          console.error(err);
          throw err.response;
        });
    },
  },
};


