import http from '@/http';
import router from "@/router";

export const authModule = {

  state: {
    token: null,
  },

  mutations: {
    LOG_IN: (state, {token}) => {
      state.token = token;
    },
    LOG_OUT: state => {
      state.token = null;
      router.push('/login')
    },
  },

  getters: {
    isLoggedIn: state => !!(state.token),
  },

  actions: {
    login({commit}, user) {
      return http.post('/login', user)
        .then(res => {
          commit('LOG_IN', {
            token: res.data.access_token,
          });
          return res.data;
        }).catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    logout({commit}) {
      return http.post('/logout')
        .catch(err => {
          if (err.response.data.message) {
            console.error(err.response.data.message);
          }
        })
        .finally((res) => {
          commit('LOG_OUT');
        });
    },
    register({ commit }, user) {
      return http.post('/register', user)
        .then(res => {
          commit('LOG_IN', {
            token: res.data.access_token,
          });
        }).catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    recover({ commit }, email) {
      return http.post('/forgot-password', email)
        .then(resp => {
          return resp.data;
        }).catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    updateAccount({ commit }, user) {
      return http.put(`/users/${user.id}`, user)
        .then(res => {
          return res.data;
        }).catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    getUser({ commit }) {
      return http.get('/user')
        .then((response) => {
          return response.data;
        }).catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    getPlayerId({ commit }, obj) {
      const id = obj.id;
      return http.get(`/users/${id}/games`)
        .then((response) => {
          return response.data;
        })
        .catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    updateId({ commit }, obj) {
      const playersId = obj.players;
      return http.post('/players', playersId)
        .then((response) => {
          return response.data;
        })
        .catch(err => {
          console.error(err);
          throw err.response;
        });
    },
    getSubscriptions({ commit }) {
      return http.get('/memberships')
        .then((response) => {
          return response.data;
        })
        .catch(err => {
          console.error(err);
          throw err.response;
        });
    }
  },
};
