<template>
  <section>
    <b-navbar :fixed-top=true type="is-primary" spaced style="text-align: left;">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/home' }">
          <strong>L'Arène de Duel</strong>
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-dropdown label="Produits">
          <b-navbar-item tag="router-link" to="/products">
            Tous
          </b-navbar-item>
          <b-navbar-item tag="router-link" v-for="tcgame in tcgames" :key="tcgame.id" :value=" tcgame.name "
                         :to="'/products?games_id=' + tcgame.id">
            {{ tcgame.name }}
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="router-link" to="/events">
          Événements
        </b-navbar-item>
        <b-navbar-item v-if="isLoggedIn" tag="router-link" to="/account">
          Mon compte
        </b-navbar-item>
        <b-navbar-item v-else tag="router-link" to="/account">
          Se connecter
        </b-navbar-item>
        <b-navbar-item tag="router-link" to="/contact">
          Contact
        </b-navbar-item>
        <b-navbar-item @click="logout" v-if="isLoggedIn">
          Déconnexion
        </b-navbar-item>
        <div v-for="tcgame in tcgames" :key="tcgame.id" v-show="!isMobile">
          <b-navbar-item v-if="tcgame.image_path" tag="router-link"
                         :to="'/products?games_id=' + tcgame.id">
            <figure>
              <img :src='imageURL(tcgame.image_path)'>
            </figure>
          </b-navbar-item>
        </div>
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button" @click="checkCart">
              <b-icon pack="fas" icon="shopping-cart"></b-icon>
              <strong>Panier ({{ nbMyProducts }})</strong>
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <b-modal v-model="open" scroll="keep" style="z-index: 999;">
      <div class="card">
        <header class="modal-card-head">
          <p class="modal-card-title">Panier</p>
          <button
            type="button"
            class="delete"
            @click="open=false"/>
        </header>
        <div class="card-content" v-if="myProducts.length>0">
          <p class="title is-4">Prix total {{ Number(myTotalPrice).toFixed(2) }} CHF</p>
          <div class="columns">
            <div class="column">
              <b-button type="is-success" @click="buy">Afficher le panier</b-button>
            </div>
            <div class="column">
              <b-button type="is-danger" @click="empty">Vider le panier</b-button>
            </div>
          </div>
          <hr>
          <div v-for="product in myProducts" :key="product.id">
            <div class="card-content">
              <b-field>
                <figure @click="goProduct(product)" style="cursor:pointer;">
                  <img v-if="product.image_path" :src='imageURL(product.image_path)' alt="image of the product"
                       style="height: 15em;">
                  <img v-else src="@/assets/logo-arene.png" alt="image of the product">
                </figure>
              </b-field>
              <b-field>
                <span>{{ product.name }}</span>
              </b-field>
              <b-field>
                <span>Prix unitaire : {{ Number(product.price).toFixed(2) }} CHF </span>
              </b-field>
              <b-field>
                <div class="columns is-vcentered">
                  <div class="column is-half is-offset-one-quarter">
                    <b-input type="number" v-model="product.cpt" min="1" @input="updateCart(product)" required/>
                  </div>
                </div>
              </b-field>
              <b-button type="is-danger" @click="removeProduct(product)">Supprimer</b-button>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {mapGetters} from 'vuex'
import router from '@/router'
import notification from "@/mixins/notification";
import {imageMixin} from '@/mixins/image'

export default {
  data() {
    return {
      open: false,
      tcgames: []
    }
  },
  mixins: [notification, imageMixin],
  computed: {
    ...mapGetters(['myProducts', 'nbMyProducts', 'myTotalPrice', 'isMobile', 'menuOpen']),
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    }
  },
  beforeMount() {
    this.checkBugCart();
    this.$store.dispatch('index', {path: 'games', data: ''})
      .then(data => {
        this.tcgames = data.data
      })
  },
  methods: {
    checkBugCart() {
      if (typeof this.nbMyProducts !== 'number') {
        this.$store.commit('RESET_CART');
      }
    },
    checkCart() {
      if (this.nbMyProducts < 1 || typeof this.nbMyProducts !== 'number') {
        this.showNotification('Votre panier est vide.', 'warning');
      } else {
        this.open = true
      }
    },
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => {
          this.showNotification('Déconnexion réussie.', 'success');
        })
    },
    updateCart(product) {
      if (product.cpt.length === 0) {
        product.cpt = 0
      }
      this.$store.commit('UPDATE_CART', product)
    },
    removeProduct: async function (product) {
      await this.$store.commit('DELETE_PRODUCT', product)
      if (this.nbMyProducts === 0) {
        this.open = false
      }
    },
    empty: function () {
      this.$store.commit('EMPTY_CART')
      this.open = false
    },
    buy: function () {
      this.open = false
      router.push({name: 'Cart'})
    },
    goProduct(product) {
      router.push({
        name: 'getProduct',
        params: {id: product.id}
      })
      this.open = false

    },
  }
}
</script>
