<template>
  <div class="container ">
    <div>
      <h3 class="is-size-1">Événements</h3>
      <b-field>
        <b-select placeholder="TCG" v-model="gameSelected" @input="updateSelect"
        >
          <option value="all">Tous</option>
          <option v-for="game in games" :key="game.id" :value=" game.id ">{{ game.name }}</option>
        </b-select>
      </b-field>
      <div>
        <v-calendar
          :class='myClass()'
          is-expanded
          :attributes='events'
        >
          <template v-slot:day-content="{ day, attributes }">
            <div class="flex flex-col h-full z-10 overflow-hidden">
              <div class="day-label text-sm text-gray-900"
                   @click="dayClicked(attributes, day.ariaLabel)"
                   style="cursor:pointer;"
              >{{ day.day }}
              </div>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <div
                  v-for="attr in attributes"
                  :key="attr.key"
                  class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                  @click="eventClicked(attr.customData, day.ariaLabel)"
                  style="
                background: #3a7bc2;
                color:white;
                cursor:pointer;
                margin:0px 5px 0px 5px;
                border-radius: 10px;"
                >
                  <p v-if="!isMobile">{{ attr.customData.name }}</p>
                  <p v-else>{{ attr.customData.name.charAt(0) }}</p>
                </div>
              </div>
            </div>
          </template>
        </v-calendar>
        <b-modal
          v-model="open"
          :width="640"
          scroll="keep"
        >
          <div class="card">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ modal.title }}</p>
              <button
                type="button"
                class="delete"
                @click="open=false"/>
            </header>
            <div class="card-content">
              <div v-if="hasEvent">
                <div v-for="myEvent in modal.events" :key="myEvent.id" :value=" myEvent.id ">
                  <div class="level-item has-text-centered" style="margin-bottom: 10px">
                    <div>
                      <p class="title is-4" >{{ myEvent.customData.name }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered" style="margin-bottom: 10px">
                    <div>
                      <p class="subtitle is-4">{{ myEvent.customData.game_name }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered" >
                    <div>
                      <p class="subtitle is-4">{{ Number(myEvent.customData.price).toFixed(2) }} CHF</p>
                    </div>
                  </div>
                  <br>
                  <div class="level-item has-text-centered">
                    <div>
                      <b-button type="is-success" @click="getDetailsEvent(myEvent)">Détails</b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>Aucun évènement pour ce jour</div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
import router from '@/router'
import {DateTime} from 'luxon'
import {mapGetters} from 'vuex'
import notification from "@/mixins/notification";

export default {
  data() {
    return {
      loadedEvents: [],
      events: [],
      open: false,
      modal: {},
      games: [],
      gameSelected: null,
      hasEvent: true
    }
  },
  mixins: [notification],
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    myClass() {
      if (this.isMobile) return ''
      return "custom-calendar"
    },
    eventPassed(event) {
      const today = DateTime.now()
      if (today.hasSame(event.date, 'day')) {
        return true
      }
      if (event.date < today) {
        return true
      }
      if (event.date > today) {
        return false
      }
    },
    updateSelect() {
      this.events = []
      this.createData(this.gameSelected)
    },
    eventClicked(event, day) {
      this.hasEvent = true
      this.modal.title = day
      this.modal.events = []
      const data = {}
      data.customData = event
      this.modal.events.push(data)
      this.open = true
    },
    dayClicked(events, day) {
      this.hasEvent = events.length > 0
      this.modal.title = day
      this.modal.events = []
      this.modal.events = events
      this.open = true
    },
    getDetailsEvent(event) {
      this.$store.commit('selectedEvent', event)
      router.push({name: 'getEvent', params: {id: event.customData.id}})
    },
    createData(game) {
      if (game === 'all') game = 0
      for (let i = 0; i < this.loadedEvents.length; i++) {
        if ((game > 0 && this.loadedEvents[i].game_id === game) || (game === 0)) {
          const myEventBdd = {}
          myEventBdd.id = this.loadedEvents[i].id
          myEventBdd.name = this.loadedEvents[i].name
          myEventBdd.game_id = this.loadedEvents[i].game_id
          myEventBdd.completeDate = new Date(this.loadedEvents[i].date)
          const myYear = myEventBdd.completeDate.getFullYear()
          const myMonth = myEventBdd.completeDate.getMonth() + 1
          const myDay = myEventBdd.completeDate.getDate()
          myEventBdd.date = DateTime.local(myYear, myMonth, myDay)
          let myMinutes = myEventBdd.completeDate.getMinutes()
          if (myMinutes.toString().length === 1) myMinutes = '0' + myMinutes
          let myHours = myEventBdd.completeDate.getHours()
          if (myHours.toString().length === 1) myHours = '0' + myHours
          myEventBdd.time = myHours + 'h' + myMinutes
          myEventBdd.price = this.loadedEvents[i].price
          myEventBdd.description = this.loadedEvents[i].description
          if (!myEventBdd.description) {
            myEventBdd.description = 'Pas de description'
          }
          let myGame = null
          myGame = this.games.find(game => game.id === myEventBdd.game_id)
          myGame ? myEventBdd.game_name = myGame.name : myEventBdd.game_name = 'Lié à aucun TCG'
          const attribute = {}
          attribute.customData = myEventBdd
          attribute.dates = myEventBdd.completeDate
          this.events.push(attribute)
        }
      }
    }
  },
  async beforeMount() {
    await this.$store.dispatch('index', {path: 'events', data: ''})
      .then(data => {
        this.loadedEvents = data.data
      }).catch(() => {
        this.showError('Impossible de charger les événements.')
      });
    await this.$store.dispatch('index', {path: 'games', data: ''})
      .then(data => {
        this.games = data.data
      })
    this.createData(0)
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }

  & .vc-weeks {
    padding: 0;
  }

  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }

  & .vc-day {
    min-height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;

    &:not(.on-bottom) {
      border-bottom: var(--day-border);
    }

    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
}
</style>
