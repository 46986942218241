<template>
  <div id="app">
    <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons">
    <NavBar/>
    <router-view/>
  </div>
</template>

<script>
import NavBar from '@/components/App/NavBar';
import {mapGetters} from 'vuex';

export default {
  data: () => {
    return {
      window: {
        width: 0,
        height: 0
      }
    };
  },
  components: { NavBar },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.$store.commit('SET_WINDOW_SIZE', this.window.width, this.window.height);
      this.window.width >= 1000 ? this.$store.commit('MOBILE_STATUS', false) : this.$store.commit('MOBILE_STATUS', true);
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
@import "./src/sass/variables.scss";
</style>
