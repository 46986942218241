import http from '@/http';
export const filterModule = {

  actions: {
    index({ commit }, obj) {
      const path = obj.path;
      const data = obj.data;
      return http.get(path + data)
    },
    getProductsFilter({ commit }, obj) {
      let research = 'products?';
      if (obj.page != null) {
        research += 'page=' + obj.page;
      }
      if (obj.tcg != null) {
        research += '&game_id=' + obj.tcg;
      }
      if (obj.category != null) {
        research += '&category_id=' + obj.category;
      }
      switch (parseInt(obj.tri)) {
        case 1:
          research += '&sort=release_date,desc';
          break;
        case 2:
          research += '&sort=release_date,asc';
          break;
        case 3:
          research += '&sort=name,asc';
          break;
        case 4:
          research += '&sort=name,desc';
          break;
        case 5:
          research += '&sort=price,asc';
          break;
        case 6:
          research += '&sort=price,desc';
          break;
        default :
          research += '&sort=release_date,desc';
          break;
      }
      if (obj.name != null) {
        research += '&name=' + obj.name;
      }
      return http.get(research)
        .catch(err => {
          console.error(err);
          throw err.response;
        });
    },
  },
};


