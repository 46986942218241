export default {
  methods: {
    showNotification(myMessage, myType,) {
      this.$toast.clear()
      this.$toast.open({
        message: myMessage,
        type: myType,
        position: 'top'
      })
    },
    showError(myMessage){
      this.$buefy.dialog.alert({
        title: 'Erreur',
        message: myMessage,
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    }
  }
}
